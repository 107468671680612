@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;500;600;700;800;900&family=Staatliches&display=swap');

@mixin staatliches {
	font-family: 'Staatliches', cursive;
}

@mixin mulish {
	font-family: 'Mulish', sans-serif;
}

@mixin buttonFont {
	@include staatliches;
}

@mixin tlbr($indent) {
	top: $indent;
	left: $indent;
	right: $indent;
	bottom: $indent;	
}

@mixin TLBR {
	@include tlbr(0);
}

@mixin f_tlbr($indent) {
	@include tlbr($indent);
	position: fixed;		
}

@mixin F_TLBR {
	@include f_tlbr(0);
}

@mixin Toggly {
	transform: translateY(0);
	transition: 0.2s transform ease-in-out;

	&--false {
		transform: translateY(100vh);
	}
}

@mixin TOGGLY {
	@include F_TLBR;
	transform: translateY(0);
	transition: 0.2s transform ease-in-out;

	&--false {
		transform: translateY(100vh);
	}
}

@mixin gradientText ($a, $b) {
	background: linear-gradient(90deg, $a, $b);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
}

@mixin scrolly {
	&::-webkit-scrollbar {
		width: 8px;
		padding: 1px;

	}

	&::-webkit-scrollbar-button {

	}

	&::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,0.6);
		border-radius: 8px;
		border:1px solid rgba(255,255,255,0.4);

	}

	&::-webkit-scrollbar-track {
		background: linear-gradient(0deg, $HEX_AccentThree, $HEX_AccentTwo);
		padding: 1px;

	}
	&::-webkit-scrollbar-track-piece {
		

	}
	&::-webkit-scrollbar-corner {


	}
}





/* SCSS HEX */
$rich-black-fogra-39: #0c0f0aff;
$winter-sky: #ff206eff;
$lemon-glacier: #fbff12ff;
$turquoise: #41ead4ff;
$white: #ffffffff;


$blackish: $rich-black-fogra-39;

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-right: linear-gradient(90deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-bottom: linear-gradient(180deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-left: linear-gradient(270deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-top-right: linear-gradient(45deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-bottom-right: linear-gradient(135deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-top-left: linear-gradient(225deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-bottom-left: linear-gradient(315deg, #0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);
$gradient-radial: radial-gradient(#0c0f0aff, #ff206eff, #fbff12ff, #41ead4ff, #ffffffff);

@mixin gradientText ($a, $b) {
	background: linear-gradient(90deg, $a, $b);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
}


$background: $blackish;
$text: $white;
$gutter: 8px;
$font: 'Ubuntu', sans-serif;


$HEX_AccentOne: $winter-sky;
$HEX_AccentTwo: $lemon-glacier;
$HEX_AccentThree: $turquoise;

$main-gradient: linear-gradient(90deg, $HEX_AccentOne, $HEX_AccentTwo);

@mixin mainGradient {
	background: linear-gradient(90deg, $HEX_AccentOne, $HEX_AccentTwo);
}

@keyframes glowGod {
	0% {filter: drop-shadow(0 0 0 $HEX_AccentOne)}
	100% {filter: drop-shadow(0 0 4px $HEX_AccentOne)}

}

$turquoise-gradient: linear-gradient(90deg, $HEX_AccentThree, $HEX_AccentOne);


$buttonBorderColor: #000;
$buttonBGColor: linear-gradient(90deg, $HEX_AccentTwo, $HEX_AccentOne);
$buttonFontColor: $blackish;
$buttonBGColorHOVER: linear-gradient(270deg, $HEX_AccentTwo, $HEX_AccentOne);
$buttonFontColorHOVER: $white;

body {
  background-color: $background;
  color: $text;
 
  padding: $gutter*2;
  @include mulish;
}

$poolFontSize: 20px;
$maxRowWidth: 480px;




$buttonFontSize: 24px;
$buttonLineHeight: 30px;
$buttonBorderSize: 0;
$buttonPadding: $gutter;

$buttonEffectiveHeight: $buttonLineHeight + $buttonBorderSize*2 + $buttonPadding*2;

$buttonRightFixedPosition: $gutter*4;
.form-check {
  position: relative;
  margin: $gutter $gutter $gutter $gutter*2;
  font-size: $poolFontSize;
  line-height: $poolFontSize + $gutter;
  width: $maxRowWidth;
  border-bottom: 2px solid $HEX_AccentThree;
  height: 43px;
  span {
  	position: relative;

  	&.PoolNameRewardContainer {
  		position: relative;


		button {
			font-size: 	16px;
			line-height: 20px;
			position: absolute;
			left: $maxRowWidth - 96px - 96px - 32px;
			width: 96px;
			padding: 0;
			
		}

		.PoolRewards {
	  		position: absolute;
	  		font-size: $poolFontSize;

	  		background-color: $HEX_AccentOne;
	  		padding: 0 $gutter;
	  		left: $maxRowWidth - 96px;

	  		width: 144px;

	  		border-top-left-radius: $gutter;
	  		border-top-right-radius: $gutter;
	  		border-bottom-right-radius: $gutter;
	  		bottom: -2px;

	  	}

	  	.PoolBalance {
	  		position: relative;
	  		font-size: $poolFontSize;

	  		background-color: $blackish;
	  		padding: 0 $gutter;
	  		//left: $maxRowWidth - 96px + 144px + 32px;
	  		left: 8px;
	  		width: 244px;

	  		border-top-left-radius: $gutter;
	  		border-top-right-radius: $gutter;
	  		border-bottom-right-radius: $gutter;

	  		border: 2px solid $HEX_AccentThree;
	  		bottom: -2px;
	  		text-align: right;
	  		.PoolName, em, strong {
	  			vertical-align: middle;
	  			display: inline-block;
	  		}
	  		$h: 32px;
	  		.PoolName .simpleToken {
	  			
				height: $h;
				width: $h;
				text-align: center;
				img {
					height: $h;
				}
			} 
			strong {
				margin-right: 8px;
			}
			em {
				margin-right: 4px;
			}
			.PoolName .LP {
				height: $h;
				background: rgba($blackish, 0.8);
				.simpleToken {
					top: -2px;
					& + .simpleToken {
						left: -4px;
					}
				}
			}
	  	}

	  	em {
	  		font-style: normal;
	  		font-size: $poolFontSize/2;
	  	}
	}




  	}

  	&.PoolName {
  		position: relative;
  		font-size: $poolFontSize;
  	}

  	 
  

  &.hasRewards--false {
  	span {
  		&.PoolRewards {
	  		display: none;
	  	}
  	}
  }
}


.TOGGLESHOWALL--true {
	.hasRewards--false {
		display: none;
	}
}

.form-group {

	position: fixed;
	top: 128px;
	right: $buttonRightFixedPosition;
	text-align: right;

	width: 600px;

	button {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
	}
	button + button {
		top: $buttonEffectiveHeight + $gutter*2;
	}
	button + button + button {
		top: ($buttonEffectiveHeight + $gutter*2)*2;
	}

}

button {

	outline: none;
	border: $buttonBorderSize solid $buttonBorderColor;
	background: $buttonBGColor;
	color: $buttonFontColor;
	font-size: $buttonFontSize;
	padding: $buttonPadding $buttonPadding*2;
	line-height: $buttonLineHeight;
	cursor: pointer;
	border-radius: 8px;
	/* box-shadow: 4px 4px 4px rgba(0,0,0,0.4); */

	transform: scale(1);
	transition: 0.2s transform ease-in-out, 0.2s box-shadow ease-in-out, 0.2s background ease-in-out, 0.2s color ease-in-out;

	&:hover {
		transform: scale(1.1);
		background: $buttonBGColorHOVER;
		color: $buttonFontColorHOVER;
		/* box-shadow: 8px 8px 8px rgba(0,0,0,0.3); */
	}

	&.harvest-button {
		font-size: 16px;
		margin-bottom: $gutter*2;
		margin-left: $gutter*2;
	}


	&.farm-button {
		position: fixed;
		bottom: $buttonRightFixedPosition;
		right: $buttonRightFixedPosition;
		padding: 2*$buttonPadding $buttonPadding*4;
		font-size: $buttonFontSize*2;
		line-height: $buttonLineHeight*2;
	}

	

	&:disabled {
		display: none;
	}

	@include buttonFont;

}


a {
	text-decoration: none;
	color: $HEX_AccentTwo;
	font-weight: 800;

	&:hover {
		color: $HEX_AccentOne;
	}
}

$controlsPadding: 16px;

.controls {
	position: fixed;
	right: 38vw;
	padding: $controlsPadding;
	
	bottom: calc(100vh - 144px - 80px);
	width: initial;
	transition: 0.2s right ease-in-out, 0.2s bottom ease-in-out 0.2s;


	button {
		margin: 0 8px; 
	}

	&.controls--lesser {
		bottom: calc(100vh - 144px) ;
		
		width: initial;
		border-bottom: 1px solid $HEX_AccentOne;

	}

	&.controls--hyperjump, 
	&.controls--tomb, 
	&.controls--steak {
		right: 0;
		
		width: 32%;
		bottom: 35vh;
		border-bottom: 1px solid $HEX_AccentOne;

		.button {
			
			margin-top: 8px;
			margin-bottom: 8px;
			display: block;

		}

		&.controlsToggle--false {
			right: -32%;
			.button {
				transform: scale(10%);
			}
		}
		&.controlsToggle--true {
			z-index: 99;
			background: linear-gradient(90deg, $blackish, rgba(0,0,0,1));
		}

	}
	&.controlsToggle--false {
		&.controls--hyperjump {

			bottom: 56vh;
		}
		&.controls--tomb {
			bottom: 64vh;
		}

		&.controls--steak {
			bottom: 48vh;
		}
	}
	
}



.control-toggle {
	$w: 128px;
	

	position: absolute;
	left: -$w - $controlsPadding*2;
	bottom: 0;
	background: none;
	transition: 0.2s transform ease-in-out;
	img {
		max-width: $w;
		max-height: 64px;
	}
	&:hover {
		background: none;
		left: -$w - $controlsPadding*2 - 16px;
	}
}


.controls--hyperjump {
	.control-toggle {
		left: -120px - $controlsPadding*2;
	}
}

.controls--tomb {
	.control-toggle {
		left: -56px - $controlsPadding*2;
		img {
			width: 64px;
		}
	}
}

.controls--steak {
	.control-toggle {
		left: -52px - $controlsPadding*2;
		img {
			width: 64px;
		}
	}
}



.Staking {
	
		position: fixed;
		bottom: $buttonRightFixedPosition + $buttonLineHeight*2 + 4*$buttonPadding + $gutter*2;
		right: 0;
		padding: 2*$buttonPadding $buttonPadding*4;

		border-bottom: 1px solid $HEX_AccentOne;

		
	button {
		margin: 0 8px;
	}

}

.Specialist {
	
		position: fixed;
		bottom: 30vh;
		right: 0;
		padding: 2*$buttonPadding $buttonPadding*4;

		border-bottom: 1px solid $HEX_AccentOne;

		
	button {
		margin: 0 8px;
	}

}


.balance {
	
	span {
		line-height: $buttonLineHeight;
		font-size: $buttonFontSize;
		vertical-align: top;

	}

	img {
		height: $buttonFontSize;
		margin-left: 4px;
		margin-right: 4px;
		vertical-align: middle;
		position: relative;
		top: -2px;
		filter: drop-shadow(0 0 2px $blackish);
	}
}

.mini-wallet {
	line-height: $buttonLineHeight;
	padding: 2px 8px;
	background: $turquoise-gradient;
	border-radius: $buttonLineHeight;
	text-align: center;
	@include buttonFont;
	color: $white;
	position: relative;
	right: -32px;

	.balance {
		position: relative;
		z-index: 1;
		img {
			margin-right: 24px;

		}
	}
	margin-bottom: 8px;
	transition: 0.2s transform ease-in-out;

	.mini-walletBlackGround {
		position: absolute;
		background: $blackish;
		width: 100%;
		border-radius: $buttonLineHeight;
		height: $buttonLineHeight;
		z-index: 0;
	}

	
}

.controlsToggle--false {
	.mini-wallet {
		transform: scale(10%);
	}
}



.LP {
	background: $HEX_AccentThree;
	border-radius: 100px;
	border: 2px solid $HEX_AccentTwo;
}



.reward-wallet {

	position: fixed;
	right: -16px;
	top: 10vh;

	transition: 0.4s transform ease-in-out;
	width: calc(100vw - 500px);

	

	&.walletToggle--false {
		transform: translateX(calc(100vw - 500px - 128px));
	}



}

.mini-wallet__name {
		position: absolute;
		color: $HEX_AccentTwo;
		left: 16px;
		cursor: pointer;
		&:hover {
			color: $HEX_AccentOne;
		}
	}

.reward-wallet--stash {
	top: calc(#{$buttonLineHeight} + 10vh + 2px);
	right: -128px;
	transition: 0.4s transform ease-out 0.2s;
	.mini-wallet__name {
		position: absolute;
		color: $HEX_AccentThree;
		left: 16px;
		cursor: text;
		&:hover {
			color: $HEX_AccentThree;
		}
	}

	.balance span {
		font-size: 20px;
	}
	
}

$swapperWidth: 80vw;

.swapper {
	overflow: hidden;
	

	$swapperGutter: calc(50vw - #{$swapperWidth} / 2);

	$toggleHeight: $buttonLineHeight + $gutter*2;

	$swapperToggleTop: calc(50vw - #{$swapperWidth} / 2 - #{$toggleHeight});

	/* width: $swapperWidth; */
	box-sizing: border-box;

	/* height: calc(100vh - 2 * #{swapperGutter}); */
	position: fixed;
	top: $swapperGutter;
	left: $swapperGutter;
	right: $swapperGutter;
	bottom: $swapperGutter;
	z-index: 999;

	border: 2px solid $HEX_AccentOne;
	background: rgba(0,0,0,0.8);

	transition: 0.2s top ease-in-out, 0.2s left ease-in-out, 0.2s bottom ease-in-out 0.1s, 0.2s right ease-in-out; 


	&__toggle {

		position: fixed;
		top: $swapperToggleTop;

		transition: 0.2s top ease-in-out, 0.2s left ease-in-out, 0.2s bottom ease-in-out, 0.2s right ease-in-out;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

	}



	&.swapperToggle--false {

		top: 100vh;
		left: $swapperGutter;
		right: $swapperGutter;
		bottom: calc(50vw - #{$swapperWidth} / 2 - 100vh);

		.swapper__toggle {

			position: fixed;
			top: calc(100vh - #{$toggleHeight});
		}
	}
}


.token-selector {
	position: absolute;
	background: rgba(0,0,0,0.8);

	$selectorGutter: 32px;
	left: $selectorGutter;
	right: $selectorGutter;
	bottom: $selectorGutter;
	top: $selectorGutter;
	border: 2px solid $HEX_AccentThree;

	box-sizing: border-box;
	padding: $selectorGutter;

	transform: translateX(0);
	transition: 0.4s transform ease-in-out;
	z-index: 9999;




	&.selectorToggle--false {
		transform: translateX(-125%);

	}

	&__token {
		display: inline-block;

		position: relative;


		$tw: 48px;
		$w: 2*$tw;

		width: $w;
		height: $w;
		border: 1px solid rgba(255,255,255,0.4);
		box-sizing: border-box;
		border-collapse: collapse;

		padding: ($w - $tw)/2 - 1px;
		background: $blackish;
		overflow: hidden;
		cursor: pointer;

		.token-dummy-bg {
			position: absolute;
			top: 0;
			left:0;
			width: 100%;
			height: 100%;
			background: $buttonBGColor;
			transform: scale(0) rotateZ(720deg);
			transition: 0.2s transform ease-in-out;

		}

		&:hover {
			.token-dummy-bg {
				transform: scale(100%) rotateZ(0);
			}



		}




		.balance {
			display: block;
			width: $tw;
			height: $tw;
			img {
				width: $tw;
				height: $tw;
				margin: 0;
				top: 0;


			}

		}
	}

}


$swapTop: 128px;
$swaph: 96px;
.swap {
	position: absolute;
	$sh: $swaph;
	
	top: $swapTop;
	$swaph: 96px;
	height: $sh;
	text-align: center;
	z-index: 99;

	.swap__pct-buttons {
		position: absolute;
		top: 0;
		right: 16px;
		bottom: 0;
		width: 256px;
		button {
			position: relative;
			
			
			&.in-pct {
				font-size: 16px;
				line-height: 16px;
				margin: 2px;
			}


		}

	}

	


	&.swap--IN {
		left: $swapTop;
		right: $swapperWidth/2;
		border-right: 2px solid $HEX_AccentThree;

		button {
			right: 0;
			margin-right: 8px;
		}
	}

	&.swap--OUT {
		left: $swapperWidth/2;
		right: $swapTop;
		button {
			left: 0;
			margin-left: 6px;
		}
	}

	.selected-token {
		display: inline-block;

		position: relative;


		$tw: 48px;
		$w: 2*$tw;

		width: $w;
		height: $w;
		border: 1px solid rgba(255,255,255,0.4);
		box-sizing: border-box;
		border-collapse: collapse;

		padding: ($w - $tw)/2 - 1px;
		background: $blackish;
		overflow: hidden;
		cursor: pointer;

		.token-dummy-bg {
			position: absolute;
			top: 0;
			left:0;
			width: 100%;
			height: 100%;
			background: $buttonBGColor;
			transform: scale(0) rotateZ(720deg);
			transition: 0.2s transform ease-in-out;

		}

		&:hover {
			.token-dummy-bg {
				transform: scale(100%) rotateZ(0);
			}



		}




		.balance {
			display: block;
			width: $tw;
			height: $tw;
			img {
				width: $tw;
				height: $tw;
				margin: 0;
				top: 0;


			}

		}
	}

	input {
		text-align:center;
		position: absolute;
		right: 8px;
		height: $swaph;
		font-size: $swaph/2;
		background: $blackish;
		width: 96px;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 2px solid $HEX_AccentOne;
		color: $HEX_AccentTwo;
		@include buttonFont;

		&:focus {
			outline: none;
			border-bottom: 2px solid $HEX_AccentThree;
		}

	}
 



}


.swapper__routesOutputs {
	position: absolute;
	top: $swapTop + $swaph + 16px;
	text-align: center;
	left: 0;
	right: 32px;
	ol {
		list-style-type: none;
		li {
			$h: 48px;
			$fs: $h/2;

			height: $h;
			font-size: $fs;
			line-height: $h;
			padding: 0 8px;

			background: $blackish;
			margin-bottom: 8px;
			border: 1px solid rgba(255, 255, 255, 0.4);
			border-radius: $h;

			filter: drop-shadow(0 0 0px $HEX_AccentOne);
			transition: 0.2s filter ease-in-out;  
			cursor: pointer;

			&:hover {
				filter: drop-shadow(0 0 8px $HEX_AccentOne);
			}


		}
	}

}


.swapper__inAmt {
	position: absolute;
	top: 16px;
	font-size: 96px;
	width: 100%;
	text-align: center;
	font-weight: 900;
	@include buttonFont;

	@include gradientText($HEX_AccentTwo, $HEX_AccentOne);

}



h1.farmgod {
	margin-top: 0;
	$fs: 96px;
	$H: 5*$fs/4;
	line-height: $H;
	font-size: $fs;
	padding-left: 16px;
	padding-right: 48px;

	@include buttonFont;

	position: relative;
	height: $H;

	display: inline-block;
	letter-spacing: 8px;

	

	
	.dummy-bg {
		position: absolute;

		background: $blackish;

		height: $H/2;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;

	



	}
	span {
		z-index: 1;
		position: relative;

		@include mainGradient;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		filter: drop-shadow(0 0 4px $HEX_AccentOne)
		/* animation-name: glowGod;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-direction: alternate; */

	}
}




.farmland {
	position: fixed;
	box-sizing: border-box;
	border-top: 2px solid $HEX_AccentTwo;
	border-bottom: 2px solid $HEX_AccentOne;
	h2 {
		@include gradientText($HEX_AccentOne, $HEX_AccentThree);
		display: inline-block;
	}
	left: 16px;
	right: 38vw;
	bottom: 64px;
	top: 144px;
	padding-left: 2vw;

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 8px;
		padding: 1px;

	}

	&::-webkit-scrollbar-button {

	}

	&::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,0.6);
		border-radius: 8px;
		border:1px solid rgba(255,255,255,0.4);

	}

	&::-webkit-scrollbar-track {
		background: linear-gradient(0deg, $HEX_AccentOne, $HEX_AccentTwo);
		padding: 1px;

	}
	&::-webkit-scrollbar-track-piece {
		

	}
	&::-webkit-scrollbar-corner {


	}


}

.pending {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 24px;


	background: rgba(255,255,255,0.5);
	
	z-index: 999999;
	font-size: 12px;
	text-align: center;
	color: $blackish;
	line-height: 24px;
	&.pending--false {
		display: none;
	}
}




.wallet {
	position: fixed;
	top: 0;
	right: 0;

	$walletWidth: 320px;

	width: $walletWidth;

	$walletEntryHeight: 48px;
	$walletEntryPadding: 4px;

	$walletEntryInnerHeight: $walletEntryHeight - 2*$walletEntryPadding;

	.wallet__entry {
		position: relative;
		box-sizing: border-box;
		height: $walletEntryHeight;
		padding: $walletEntryPadding;

		border-bottom: 1px dotted $HEX_AccentOne;

		transition: 0.2s height ease-in-out, 0.1s border-bottom-width ease-in-out;
	}

	.token-name {
		position: absolute;
		top: 0;
		left: 0;
		height: $walletEntryHeight;
		font-size: $walletEntryInnerHeight*2/3;
		line-height: $walletEntryHeight;
		
		text-align: right;
		width: calc(100% - 16px);

		transform: scale(1);
		transition: 0.2s transform ease-in-out;

		@include gradientText(white, rgb(40,40,40));
		/* filter: drop-shadow(0 0 1px $HEX_AccentOne); */

		}




	.token-icon {
		$tiW: $walletEntryInnerHeight;

		display: inline-block;
		height: $walletEntryInnerHeight;
		position: relative;

		width: $tiW;
		padding: 4px;
		box-sizing: border-box;
		img {
			width: 100%;
			filter: drop-shadow(0 0 1px white);
		}

		vertical-align: top;

		transform: translateX(0) translateY(0);

		transition: 0.2s transform ease-in-out;

		&--polydoge {
			text-align: center;
			img {
				width: initial;
				height: 100%;

			}
		}

		&--amdai,
		&--amusdc,
		&--amweth,
		&--amwbtc,
		&--amwmatic {
			img {
				filter: drop-shadow(0 0 4px $HEX_AccentThree);
			}
			
		}



	}

	.token-balance {
		display: inline-block;
		vertical-align: top;

		height: $walletEntryInnerHeight;
		line-height: $walletEntryInnerHeight;
		position: relative;
		left: 16px;

		transform: scale(1);
		transition: 0.2s transform ease-in-out;

	}

	.collapsible {
		background: linear-gradient(0deg, $blackish, $blackish, $blackish, $blackish, $blackish, $blackish);
		border-left: 2px solid $HEX_AccentTwo;
		border-bottom: 2px solid $HEX_AccentThree;
		transition: 0.2s height ease-in-out, 0.2s padding ease-in-out, 0.2s background ease-in-out, 0.2s border ease-in-out;
		box-sizing: border-box;
		position: relative;

		&__cover {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		
			text-align: center;
			line-height: $walletEntryHeight;
			font-size: $walletEntryInnerHeight*2/3;
			color: $HEX_AccentOne;
			opacity: 0;
			@include gradientText($HEX_AccentOne, $HEX_AccentTwo);
			font-weight: 800;
			filter: hue-rotate(0);
			transition: 0.2s opacity ease-in-out 0.2s, 0.2s filter ease-in-out;

		}

		&__coverBack {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $blackish;
			opacity: 0;
			transition: 0.2s opacity ease-in-out 0.2s;
		}


		&.TOGGLE__collapsible--false {
			background: linear-gradient(0deg, adjust-color($HEX_AccentOne, $lightness: -25%), $blackish, $blackish, $blackish, $blackish, adjust-color($HEX_AccentOne, $lightness: -25%));
			height: $walletEntryHeight;
			padding: $walletEntryPadding;
			
			border-left: 0px solid $blackish;
			.collapsible__coverBack {
				opacity: 0.8;
			}

			.collapsible__cover {
				opacity: 1;
			}
		


			cursor: pointer;
			.wallet__entry {
				height: 0;
				border-bottom-width: 0px; 
			}
			.token-name, .token-balance {
				transform: scale(0);
			}
			border-bottom: 1px dotted $HEX_AccentOne;

			@for $n from 1 through 10 {
				.wallet__entry:nth-child(#{$n}) {
					.token-icon {
						transform: translateX( ( $n - 1 )*$walletEntryInnerHeight ) translateY(-( $n - 1 )*$walletEntryPadding*2 - $walletEntryPadding );
					
					}
				} 
			}



		}

		&:hover {
			.collapsible__cover {
				filter: hue-rotate(180deg);
			}

		}
	}
}

$LPTokenHeight: 30px;

.dfyn-pool {
	width: 184px;

	display: inline-block;
	& + .dfyn-pool {
		margin-left: 16px;
	}

	border-left: 1px solid $HEX_AccentOne;
	padding-left: $gutter;

	.dfyn-pair {
		font-size: 20px;
		margin-bottom: $gutter;

	}
	.staked-balance {
		display: inline-block;
		vertical-align: top;
		line-height: $LPTokenHeight;
		position: relative;
		left: 8px;
		border-bottom: 4px solid $HEX_AccentThree;

	}

	.dfyn-rewards {
		width: 156px;
		margin-top: $gutter;
		background: adjust-color($HEX_AccentOne, $lightness: -90%);
	
		padding: $gutter;
	
		cursor: pointer;
		text-align: right;

		border-radius: 4px;
		border-left: 2px solid $HEX_AccentTwo;

		transition: 0.2s border-left ease-in-out, 0.2s background ease-in-out;

		&:hover{
			border-left: 8px solid $HEX_AccentTwo;

			background: adjust-color($HEX_AccentOne, $lightness: -50%);



			h3 {
				span {
					transform: scale(0);
				}
				span + span {
					transform: scale(1) rotateZ(-25deg);
				}
			}
		}

		h3 {
			display: inline-block;
			
			margin-bottom: 0; 
			margin-top: 0;
			text-align: center;
			width: 100%;
			position: relative;
			height: 24px;
			font-size: 18px;

			span {
				display: inline-block;
				transform: scale(100%);
				transition: 0.2s transform ease-in-out 0.1s;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				@include gradientText($HEX_AccentTwo, $HEX_AccentThree);
			}

			span + span {
				transform: scale(0%) rotateZ(0deg);
				font-weight: 900;
				font-size: 36px;
				@include gradientText($HEX_AccentOne, $HEX_AccentTwo);
			}
		}
		.reward {
			$rewardHeight: 36px;


			height: $rewardHeight;
			padding: 8px;

			span {
				font-size: $rewardHeight/2;
				line-height: $rewardHeight;
				vertical-align: top;
				display: inline-block;

			}
			img {
				height: $rewardHeight;
				vertical-align: top;
				display: inline-block;
				position: relative;
				left: 8px;


			}
		}
	}
	
}


.LP-Token {
	display: inline-block;
	$h: $LPTokenHeight;
	$pad: 3px;

	box-sizing: border-box;
	height: $h;
	padding: $pad;
	border: 1px solid $HEX_AccentTwo;
	background: $turquoise-gradient;

	width: $h*2;
	border-radius: $h;

	img {
		height: $h - $pad*2;
		filter: drop-shadow(0 0 2px $HEX_AccentTwo);
		position: relative;
		top: -1px;
		left: 3px;
	}
	img + img {
		left: 0;
	}
}


.ControlPanel {

	border-left: 2px solid rgba(255,255,255,0.2);
	border-top: 2px solid rgba(255,255,255,0.2);

	position: fixed;

	height: 50vh;
	bottom: 0;
	right: 0;
	left: 50vw;
	z-index: 999999;
	box-sizing: border-box;


	background: linear-gradient(135deg, rgba($blackish, 1), rgba($blackish, 0.8), rgba($HEX_AccentOne, 0.3));
	border-top-left-radius: 50vh;
	border-top-right-radius: 24px;


	transition: 0.2s bottom ease-in-out;

	button {
		$h: 48px;
		height: $h;

		$pVert: $h/5;
		padding: $pVert $h/4;
		box-sizing: border-box;
		font-size: ($h - 2*$pVert)*2/3;
		line-height: $h - 2*$pVert;

		border: 2px solid rgba($blackish, 0.4);

		filter: drop-shadow(0 0 2px $HEX_AccentOne);
	


		span {
			display: inline-block;
			height: $h - $pVert*2;
			vertical-align: middle;
		}
		div {
			display: inline-block;
			height: $h - $pVert*2;
			vertical-align: middle;
		}
	}


	button.control-panel-toggle {
		position: absolute;
		top: -16px;
		right: 8px;
		font-size: 20px;
		padding: 2px 6px;
		height: 32px;
		
	}

	.focus-button {

		position: absolute;
		border: 2px solid rgba($blackish, 0.4);

		filter: drop-shadow(0 0 2px $HEX_AccentOne);

		&--one {

			bottom: 16px;
			left: 16px;

		}

		&--two {

			bottom: 64px;
			left: 0px;

		}

		.simpleToken {

			display: inline-block;

			
			position: relative;
			left: 4px;
			
			img {
				height: 100%;
				filter: drop-shadow(0 0 2px rgba(0,0,0,1))
			}
		}
	}

	$interiorTop: 32px;
	.ControlPanel__interior {
		position: absolute;
		top: $interiorTop;
		left: 20vw;
		right: 0;

		.button {
			margin: 4px;
		}

	}

	&--false {
		bottom: calc(#{$interiorTop} - 50vh);
	}
}


.simpleToken {

	display: inline-block;

	
	position: relative;
	left: 0;
	top: -1px;

	padding: 2px;
	box-sizing: border-box;
	
	img {
		height: 100%;
		filter: drop-shadow(0 0 2px rgba(0,0,0,1))
	}

	margin-left: 2px;
	margin-right: 2px;
}


.isReadyToLoadImages--false {
	display: none;
}

.isReadyToLoadImages--true {
	display: block;
}


$KnownPairsTop: 60vh;


.Token__KnownPairs {
	box-sizing: border-box;

	ul, ol {
		padding-inline-start: 0;

		li {
			display: inline-block;
			border: 1px solid white;
			padding: 2px 8px;
			border-radius: 32px;
			margin: 2px 6px 8px 2px;
			background: rgba($blackish, 0.4);
			filter: drop-shadow(0 0 4px rgba(black, 0.8));
			.simpleToken {
				$h: 32px;
				height: $h;

				&::after {
					content: ">>";
					display: inline-block;
					font-size: 16px;
					line-height: $h;
					vertical-align: top;
					margin-left: 4px;

					position: relative;
					left: 2px;
					top: -2px;

				}

				&:last-child {
					&::after {
						content: "";
						display: none;
					}
				}

			}

		}
		
	}
}

.Selected__Token {
			position: relative;
			box-sizing: border-box;
			$size: 20vw;
		

			width: $size;
			height: $size;
			border: 2px solid $HEX_AccentTwo;

			border-top-left-radius: $size/2;
			border-bottom-right-radius: $size/2;
			background: rgba($blackish, 0.2);

			.Token__inoutLabel {
				position: absolute;
				bottom: -4px;
				left: 8px;
				font-size: 48px;

				@include buttonFont;
				@include gradientText($HEX_AccentOne, $HEX_AccentTwo);

			}

			.simpleToken {
				position: relative;
				box-sizing: border-box;
				top: 0;
				padding: calc(25vw/6);
				margin: 0;
				width: 100%;
				height: 100%;
				text-align: center;

				img {
					
				}

			}
		}

.GodSwap__Swapper {
	@include F_TLBR;
	transition: 0.4s right ease-in-out;

	$leftBGColor: adjust-color($HEX_AccentOne, $lightness: -40%);
	$rightBGColor: adjust-color($HEX_AccentThree, $lightness: -40%);

	&.GodSwap__Swapper--false {
		right: 100vw;
		.Swapper__Half, .Swapper__Controls {
			transform: translateY(100vh);
		}

	}
	.Swapper__Half {
		cursor: pointer;
		position: absolute;
		box-sizing: border-box;
		top: 0;
		bottom: 0;

		left: 0;
		right: 50%;
		padding-top: 144px;
		background: $leftBGColor;
		transform: translateY(0);

		transition: 0.4s transform ease-in-out 0.4s, 0.2s filter ease-in-out;

		&:hover {
			filter: hue-rotate(-20deg);

			& + .Swapper__Controls {
				filter: drop-shadow(0 0 4px black);
			}

			& + .Swapper__Half + .Swapper__Controls {
				filter: drop-shadow(0 0 4px black);
			}


		}
		

		& + .Swapper__Half {
			left: 50%;
			right: 0;

			background: $rightBGColor;
			transition: 0.4s transform ease-in-out 0.6s;

			.Token__KnownPairs {
				border-left: 1px solid white;
				border-right: none;
				padding-right: 0;
				padding-left: 16px;
				left: calc(11vw + 2px);
				text-align: left;
			}

		}
		

		.Selected__Token {
			position: relative;
			box-sizing: border-box;
			$size: 20vw;
			left: (50vw - $size)/2;

			width: $size;
			height: $size;
			border: 2px solid $HEX_AccentTwo;

			border-top-left-radius: $size/2;
			border-bottom-right-radius: $size/2;
			background: rgba($blackish, 0.2);

			.Token__inoutLabel {
				position: absolute;
				bottom: -4px;
				left: 8px;
				font-size: 48px;

				@include buttonFont;
				@include gradientText($HEX_AccentOne, $HEX_AccentTwo);

			}

			.simpleToken {
				position: relative;
				box-sizing: border-box;
				top: 0;
				padding: calc(25vw/6);
				margin: 0;
				width: 100%;
				height: 100%;
				text-align: center;

				img {
					
				}

			}
		}
		.Token__KnownPairs {
			width: 30vw;
			position: absolute;
			bottom: 48px;
			top: $KnownPairsTop;
			left: 10vw;
			text-align: right;
			border-right: 1px solid white;
			padding-right: 16px;
		}
	}
	.Swapper__Controls {
		position: absolute;
		left: 40vw;
		width: 20vw;
		top: 0;
		bottom: 0;
		background: linear-gradient(90deg, $leftBGColor, $rightBGColor);
		border-left: 8px solid $leftBGColor;
		border-right: 8px solid $rightBGColor;
		transform: translateY(0);
		transition: 0.4s transform ease-in-out 0.1s, 0.2s filter ease-in-out;


	}
}

.GodSwap__TokenSelector {
	overflow-y: scroll;
	position: fixed;
	box-sizing: border-box;

	top: 128px;
	left: 10vw;
	right: 10vw;
	bottom: 0;

	padding: 32px;

	background: rgba(adjust-color($blackish, $lightness: -20%), 0.9);

	text-align: center;

	transition: 0.2s transform ease-in;

	border: 4px solid rgba($HEX_AccentOne, 0.4);

	transform: translateY(0);

	&--false {
		transform: translateY(100vh);
		
	}
	@include scrolly;
}

.TokenSelector__Token {
	cursor: pointer;
	overflow: hidden;
	position: relative;
	display: inline-block;
	box-sizing: border-box;

	$size: 144px;

	height: $size;
	width: $size;
	border: 2px solid $HEX_AccentOne;
	border-radius: 0;

	$mH: 6px;
	$mV: $mH - 2px;

	margin: $mV $mH;


	transition: 0.4s border-radius ease-out 0.2s;


	.dummy-bg {



		position: absolute;
		top: 0;
		left: 0;
		@include mainGradient;
		width: $size;
		height:	$size;

		transform: scale(0) rotateZ(0);
		transition: 0.2s transform ease-in-out;
	}

	&:hover {

		border-radius: $size/2;
		.dummy-bg {
			transform: scale(1) rotateZ(1440deg - 45deg);

		}

	}

	.simpleToken {
		position: relative;
		box-sizing: border-box;
		top: 0;
		padding: $size/6;
		margin: 0;
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			
		}

	}

}

.GodSwap__Toggle {
	position: fixed;
	bottom: $gutter*2;
	left: $gutter*2;
}


.Token__KnownPairs.Swapper__Routes {
	position: absolute;
	top: calc(#{$KnownPairsTop});
	width: 100%;
	h2 {
		text-align: center;
		width: 100%;
	}
	ul, ol {
		padding-inline-start: 0;
		text-align: center;
		li {
			position: relative;
			margin-bottom: 8px;
			.simpleToken {
				&:nth-last-child(2) {
					&::after {
						content: "";
						display: none;
					}
					
				}
			}
			

		}
		li.potential.potential--pair {
			.simpleToken {
				&:nth-last-child(2) {
					&::after {
						content: ">>";
						display: inline-block;
					}
				}
			}
		}
	}

}

.SpekTok--amwmatic,
.SpekTok--amweth,
.SpekTok--amwbtc,
.SpekTok--amusdc,
.SpekTok--amdai {
	filter: drop-shadow(0 0 8px $HEX_AccentThree);
}



.selected-route {
	
	position: absolute;
	top: 0;
}


.matic-wrapper {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 48px;
	button {
		margin-right: 8px;
		padding-right: 2px;
	

		.simpleToken {
			vertical-align: bottom;
			height: 32px;

			img {
				height: 32px;
			}
		}

		
		
	}
}

.Swapper__Input, .CrvSwap {

	h3 {
		font-size: 40px;
		@include buttonFont;
		@include gradientText($HEX_AccentOne, $HEX_AccentThree);
		margin-bottom: 4px;
		text-align: center;
	}

	input {
		@include buttonFont;
		color: rgba($blackish, 0.8);
		text-align: center;

		margin-top: 8px;
		box-sizing: border-box;
		padding: 2px;
		width: 100%;
		background: none;
		border: 2px solid rgba($blackish, 0.4);
		font-size: 42px;
		
		&:focus {
			outline: none;
			filter: drop-shadow(0 0 2px $HEX_AccentThree);
		}
	}
	
}

.amount-btns {
	button {
		background: linear-gradient(90deg, $HEX_AccentThree, $HEX_AccentOne);
		width: 25%;
	}
}

.amount-btns--2 {
	margin-top: 8px;
	margin-bottom: 8px;
	button {
		width: 50%;
		background: linear-gradient(90deg, $HEX_AccentTwo, $HEX_AccentOne);
	}
	
}

.int--false {
	display: none;
}

.check-routes {
	margin-top: 16px;
	button {
		width: 100%;
	}
}

.Route__Results {
	position: relative;
	
	
	background: none;
	font-size: 20px;
	line-height: 32px;
	margin-bottom: 8px;

	span {
		display: block;
		
	}

	div {
		border-bottom: 1px solid rgba($blackish, 0.4);
		padding-bottom: 2px;
		padding-left: 4px;
		cursor: pointer;
		&:hover {
			background: $HEX_AccentOne;
		}
	}


}

.the-routes {
	list-style: cjk-ideographic;
	padding: 4px;
	padding-inline-start: 64px;
	margin-left: 8px;
	margin-top: 0;
	width: 100%;
	box-sizing: border-box;
	height: 156px;
	overflow-y: scroll;
	border-top: 1px solid $HEX_AccentTwo;
	border-bottom: 1px solid $HEX_AccentThree;
	&::-webkit-scrollbar {
		width: 8px;
		padding: 1px;

	}

	&::-webkit-scrollbar-button {

	}

	&::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,0.6);
		border-radius: 8px;
		border:1px solid rgba(255,255,255,0.4);

	}

	&::-webkit-scrollbar-track {
		background: linear-gradient(0deg, $HEX_AccentThree, $HEX_AccentTwo);
		padding: 1px;

	}
	&::-webkit-scrollbar-track-piece {
		

	}
	&::-webkit-scrollbar-corner {


	}

}
.choose-route {
	margin-top: 16px;
	margin-bottom: 2px;
	text-align: center;
}

.GodForge__Toggle {
	position: fixed;
	bottom: $gutter*2;
	left: $gutter*2 + 128px;
}

.design-top-bar {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 24px;
	@include mainGradient;
}

.forge {
	@include F_TLBR;
	@include Toggly;
	background: $blackish;

	.design-top-bar {
		@include mainGradient;
	}

	.design-forge {
		position: absolute;
		top: 96px;
		left: 266px;
		font-size: 48px;
		font-weight: 800;
		display: inline-block;

		@include buttonFont;
		@include gradientText($HEX_AccentThree, $HEX_AccentTwo);
	}
	$selectedSize: 20vw;
	.Selected__Token {
		cursor: pointer;
			position: absolute;
			box-sizing: border-box;
			$size: $selectedSize;
			top: 32px;
			right: 32px;

			width: $size;
			height: $size;
			border: 2px solid $HEX_AccentTwo;

			border-top-left-radius: $size/2;
			border-bottom-right-radius: $size/2;
			background: rgba($blackish, 0.2);

			.Token__inoutLabel {
				position: absolute;
				bottom: -4px;
				left: 8px;
				font-size: 48px;

				@include buttonFont;
				@include gradientText($HEX_AccentOne, $HEX_AccentTwo);

			}

			.simpleToken {
				position: relative;
				box-sizing: border-box;
				top: 0;
				padding: calc(25vw/6);
				margin: 0;
				width: 100%;
				height: 100%;
				text-align: center;

				img {
					
				}

			}
		}

	.Token__KnownPairs {
		position: absolute;
		top: 144px;
		left: 32px;
		right: 21vw;

		h2 {
			display: inline-block;
			@include gradientText($HEX_AccentOne, $HEX_AccentTwo);
			margin-bottom: 8px;
			font-size: 48px;
			
		}
		

		ul, ol {
		

			li {
				cursor: pointer;
				background: $blackish;
				filter: drop-shadow(0 0 4px $blackish);
				transition: 0.2s filter ease-in-out;
				border-radius: 64px;
				
				.simpleToken {
					height: 64px;
					
					&::after {
						content: "+";
						line-height: 64px;

					}

					&:last-child {
						&::after {
							content: "";
							display: none;
						}
					}
					&:nth-last-child(2) {
						&::after {
							content: "+";
							display: inline-block;
						}
					}

				}

				&:hover {
					filter: drop-shadow(0 0 4px $HEX_AccentOne);
				}

			}
			
		}
	}



}

$indent: 128px;
.PairGod {
	@include f_tlbr($indent);

	box-sizing: border-box;
	border: 4px solid rgba(white, 0.2);
	border-radius: 4px;
	
	background: linear-gradient(90deg, $blackish, $HEX_AccentOne);
	transform: scale(1);
	transition: 0.2s transform ease-in-out;
	filter: drop-shadow(0 0 16px rgba(255, 255, 255, 0.4));


	.thePair {
		display: inline-block;
		padding: 2px 16px;
		height: 144px;
		border: 4px solid $blackish;
		border-radius: 144px;
		margin: 16px;
		background: rgba($blackish, 0.8);
		filter: drop-shadow(0 0 4px $HEX_AccentTwo);
		.simpleToken {
			height: 100%;
		}
	}

	&--false {
		transform: scale(0);
	}

}

.PairGod--false + .PairGod-close-btn {
	transform: scale(0);
}

.PairGod-close-btn {
	transform: scale(1);
	transition: 0.2s transform ease-in-out;
	position: fixed;
	top: $indent + 16px;
	right: $indent + 16px;
}


.CrvGod__Toggle {
	position: fixed;
	bottom: $gutter*2;
	left: $gutter*2 + 128px*2;
}


.CrvGod {
	@include TOGGLY;
	background: $blackish;

	.design-top-bar {
		background: $turquoise-gradient;
	}
}

.curve-pool {
	width: 100%;
	text-align: center;
	.curve-pool__token {
		display: inline-block;

	}
}
.balance-LP {
	text-align: center;
}

.ThreeLP {
	$totalHeight: 64px;
	position: relative;


	$h: $totalHeight/2;
	.simpleToken {
		height: $h;
		img {
			height: $h;
		}
	}
}

.CrvSwap {
	border-top: 2px dashed $HEX_AccentOne;
	$h: 256px;
	width: 100%;
	height: $h;
	.Swapper__Input, input, .CrvGod__Swap-output {
		display: inline-block;
		box-sizing: border-box;
		height: $h;
		vertical-align: top;
		margin: 0;
	}

	.CrvGod__Swap-output, input {
		width: 30%;
	}
	input {
		
		color: $HEX_AccentOne;
		border-color: $HEX_AccentOne;
		&:focus {
			filter: drop-shadow(0 0 4px rgba($HEX_AccentTwo, 0.4));
		}
	}

	.Swapper__Input {
		width: 40%;
	}
	

}

.harvest-buttons {
	position: relative;
	text-align: right;
	.button {
		margin-top: 8px;
		margin-right: 8px;
	}
}


.zone-select {
	position: absolute;
	top: 0;
	right: 0;
	button {
		margin-right: 8px;
	}
}

.zone {
	display: none;
	position: absolute;
	top: 144px;
	left: 0;
	right: 0;
	bottom: 0;
}

.zone-swap.selected--swap,
.zone-deposit.selected--deposit,
.zone-stake.selected--stake {
	display: block
}

.curve-pool__token {
	cursor: pointer;
	.Selected__Token {
		&:hover {
			border-color: white;
		}
	}
	
}
.curve-pool__token--dai.selected-in--dai,
.curve-pool__token--usdc.selected-in--usdc,
.curve-pool__token--usdt.selected-in--usdt {
	$inColor: $HEX_AccentThree;
	.Selected__Token {
		border-color: $inColor;

		&:after {
			content: "in";
			position: absolute;
			font-size: 24px;
			bottom: 0;
			left: 8px;
			color: $inColor;
		}
		&:hover {
			border-color: white;
		}
		
	}
	
}

.curve-pool__token--dai.selected-out--dai,
.curve-pool__token--usdc.selected-out--usdc,
.curve-pool__token--usdt.selected-out--usdt {
	$inColor: $HEX_AccentOne;
	.Selected__Token {
		border-color: $inColor;

		&:after {
			content: "out";
			position: absolute;
			font-size: 24px;
			bottom: 0;
			left: 8px;
			color: $inColor;
		}
		&:hover {
			border-color: white;
		}
	}
	
}



input.form-check-input {
	appearance: none;
	$s: 12px;
	width: $s;
	height: $s;
	border-radius: $s;
	box-sizing: border-box;
	border: 1px solid white;
	position: relative;
	top: 2px;

	&:checked {
		background: $buttonBGColor;
	}
	
}

